@media screen and (max-width:1400px){
    .main-fertilizer .sec-one > div{
        width: 40%;
        height: 24rem;
    }
    .main-fertilizer .sec-one > div > div{
        right: -140%;
        top: 5%;
        width: 60vw;
        height: fit-content;
        padding: 1rem 0.5rem 0 0.5rem;
        /* background-color: red; */
    }
    .main-fertilizer .sec-one > div > div h2{
        margin: 0;
        /* background-color: orange; */
        font-size: 1.7rem;
    }
    .main-fertilizer .sec-one > div > div p{
        /* background-color: green; */
        font-size: 1rem;
        line-height: 31px;
    }
    .contact-us-section div:nth-child(1) > div{
        width: 100vw;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        z-index: 10;
    }
    .contact-us-section{
        margin-bottom: 10vw;
        display: block;
        width: 100%;
        margin-bottom: 20%;
        position: relative;
    }
    
    .contact-us-section > div:nth-child(1){
        width: 100%;
        height: fit-content;
    }
    .contact-us-section > div:nth-child(1) form{
        width: 50vw;
        position: absolute;
        right: 0;
        left: 25vw;
    }
    .contact-us-section > div:nth-child(2){
        width: 100%;
        position: relative;
        bottom: 6rem;
        left: 0;
        height: 55vw;
    }
   
}
@media screen and (max-width:1190px){
    .main-fertilizer .sec-one > div{
    width: 50%;
    }
    .main-fertilizer .sec-one > div > div{
        right: -96%;
        top: 0;
        width: 48vw;
    }
    .main-fertilizer .sec-one > div > div h2{
        font-size: 1.2rem;
        margin-bottom: 0.3rem;
    }
    .main-fertilizer .sec-one > div > div p{
        font-size: 0.8rem;
    }
    .main-app-features .option-bar{
        gap: 1rem;
        overflow-x: auto;
        bottom: -4rem;
    }
    /* .main-app-features .option-bar .btn-div{
        padding: 0.4rem 1rem;
    }
    .main-app-features .option-bar .btn-div a{
        font-size: 0.7rem;
    }
    .main-app-features .option-bar .btn-div .pointer{
        width: 1rem;
        height: 1rem;
    } */
    .nutri-source-section .container {
        padding: 1rem;
        box-sizing: border-box;
        position: relative;
    }
    .nutri-source-section .container > div:nth-child(1){
        position: absolute;
        top: 0;
        left: 30%;
        z-index: 5;
        display: none;
    }
    .nutri-source-section .container > div:nth-child(2){
        background-image: none;
        z-index: 6;
        padding: 1rem;
        box-sizing: border-box;
        flex-wrap: wrap;
        align-items: center;
        width: 100%;
        background-color: #16574fa1;
        backdrop-filter: blur(3px);
        border-radius: 58px;
        color: white;
    }
    .nutri-source-section .container > div:nth-child(2) p ,.nutri-source-section .container > div:nth-child(2) h2{
        color: white;
        font-size: 15px;
        line-height: 26px;
    }
    .main-nev .bottom .cnrtls{
        gap: 0.2rem;
    }
    .main-nev .bottom div a{
        font-size: 13px;
    }
}
@media screen and (max-width:1100px){  
    
    .main-fertilizer .sec-one > div > div {
        top: 100%;
        right: 4%;
        width: 90vw;
    }
    
    .main-fertilizer .sec-one > div {
        width: 100vw;
        margin-bottom: 30vh;
    }
    .hero-section-one {
        align-items: baseline;
    }
    .hero-section-one div:nth-child(1){
        width: 60vw;
        margin-top: 10%;
    }
    .hero-section-one div:nth-child(2){
        bottom: -22%;
        width: 80vw;
    }
    .aboutUs-container .aboutSec {
        /* width: 100%; */
        flex-wrap: wrap;
    }
    .aboutUs-container .aboutSec > div:nth-child(2) {
        width: 100vw;
    }
    .aboutUs-container .aboutSec > div:nth-child(2) > div{
        width: 100%;
    }
    .aboutUs-container .aboutSec > div:nth-child(1){
        width: 100vw;
        display: flex;
        justify-content: center;
    }
    .aboutUs-container .aboutSec div:nth-child(1) img{
        width: 35vw;
    }
}
@media screen and (max-width:1040px){
    .news-page .container{
        justify-content: center;
    }
    
}
@media screen and (max-width:1020px){
    
    .proposition .sec-one > div:nth-child(1){
        width: 100%;
    }
    .proposition .sec-one > div:nth-child(1) img{
        height: 20rem;
    }
    .proposition .sec-one > div:nth-child(2){
        width: 100%;
    }
    .proposition .sec-two > div:nth-child(1){
        width: 100%;
    }
    .proposition .sec-two > div:nth-child(2) img{
        margin: 3rem 0;
        height: 20rem;
    }
    .proposition .sec-two > div:nth-child(2){
        width: 100%;
    }
    .proposition .sec-three > div:nth-child(1){
        width: 100%;
    }
    .proposition .sec-three > div:nth-child(1) img{
        height: 20rem;
    }
    .proposition .sec-three > div:nth-child(2){
        width: 100%;
    }
    .contact-us-section {
        margin-bottom: 25%;
    }
    .contact-us-section > div:nth-child(1) form{
        width: 60vw;
        left: 20vw;
    }
    .contact-us-section > div:nth-child(2){
        height: 60vw;
    }
    .team-chart{
        padding: 1rem 5vw;
    }
    .main-nev .bottom{
        width: 100%;
        border-radius: 0;
    }
    .main-nev .bottom .bar div:nth-child(1){
        width: 100%;
    }
    .main-nev .bottom .bar div:nth-child(2){
        width: 10%;
    }
    .main-nev .bottom .bar{
        display: flex;
        width: 100%;
        justify-content: space-between;
        padding: 0;
        padding-right: 1rem;
        box-sizing: border-box;
        font-size: 1.5rem;
    }
    .main-nev .bottom div a{
        font-size: 15px;
    }
    .main-nev .bottom div{
        padding: 0.3rem 0;
    }
    .main-nev .bottom {
        position: relative;
    }
    .main-nev .bottom #hidden{
        display: flex;
        flex-direction: column;
        position: absolute;
        bottom: -16.8rem;
        border-radius: 0 0 10px 10px;
        border-bottom: 1px solid rgba(193, 193, 193, 0.6);
        z-index: 15;
    }
    .main-nev .bottom #hidden > div:hover {
        background-color: var(--color-primary-light);
        transition: 0.6s;
    }
    .main-nev .bottom #hidden > div:hover > a{
        transition: 0.6s;
        font-size: 15px;
        color: white !important;
    }
    .main-nev .bottom #hidden .active-head a{
        color: white;
    }
    .main-nev .bottom #hidden div {
        margin: 0.65rem 0;
    }
    .main-nev .bottom #hidden div a{
        color: var(--color-primary-dark);
    }
    .main-nev .bottom .cnrtls{
        gap: 0.3rem;
        display: none;
        background-color: rgba(255, 255, 255, 1);
    }
    /* .main-app-features .option-bar{
        gap: 0.8rem;
    }
    .main-app-features .option-bar .btn-div{
        padding: 0.2rem 0.7rem;
        font-weight: normal;
    }
    .main-app-features .option-bar .btn-div a{
        font-size: 0.2rem;
    }
    .main-app-features .option-bar .btn-div .pointer{
        width: 0.9rem;
        bottom: -7.5px;
        height: 0.9rem;
    } */
    .main-nev .bottom{
        gap: 0.3rem;
    }
    .our-advice-sec{
        flex-wrap: wrap;
        padding: 0;
        margin-bottom: 2rem;
        justify-content: center;
    }
    .our-advice-sec div:nth-child(1){
        min-width: 30rem;
    }
    .our-advice-sec div:nth-child(2){
        text-align: center;
        padding: 0;
        background-color: rgba(222, 222, 222,0.1);
        /* box-shadow: 0px 5px 10px 2px rgba(161, 161, 161,0.5); */
        backdrop-filter: blur(6px);
        width: 100%;
        padding: 0 4rem;
    }
    .app-features-sec{
        padding: 0.5rem 1rem;
    }
    .left .content-sec{
        width: 70%;
        padding-right: 0;
    }
    .right .content-sec{
        width: 70%;
        padding-left:0;
    }
    
}
@media screen and (max-width:990px){    
    .main-fertilizer .sec-one > div {
        margin-bottom: 40vh;
    }
    .features-section .features-container{
        gap: 7rem;
    }
}
@media screen and (max-width:960px){
    .underline-head h3{
        font-size: 25px;
        line-height: 30px;
    }
    .agri-advice-section > div > p, .how-we-help-sec p, .our-advice-2 > p, .goals-chart > p, .top-agro > p, .hero-section-two  p{
        /* background-color: red; */
        width: 80vw;
        font-size: 15px;
        line-height: 26px;
    }
    .hero-section-two > div:nth-child(2), .agri-advice-section > div:nth-child(2) {
        width: 80vw;
    }
    p{
        font-size: 13px;
    }
    .contact-us-section {
        margin-bottom: 30%;
    }
    .contact-us-section > div:nth-child(1) form{
        width: 70vw;
        left: 15vw;
    }
    .contact-us-section > div:nth-child(2){
        height: 65vw;
    }
    .main-hero-sub h2{
        font-size: 25px;
        line-height: 30px;
    }
    .main-hero-sub p{
        font-size: 12px;
        line-height: 20px;
        margin: 0;
    }
    .main-hero-sub{
        padding: 0;
        height: 30vw;
    }
    .investors-face-container{
        gap: 2rem;
        bottom: -3.6rem;
    }
    .investors-face-container > div {
        width: 11%;
    }
}
@media screen and (max-width:870px){
    .updates-main{
        flex-wrap: wrap;
    }
    .updates-main .cont, 
    .updates-main .updates{
        width: 100%;
        padding: 0;
    }
    .home-bg .container{
        width: 90vw;
        margin-top: 1rem;
    }
    .home-bg .container section ul li{
        font-size: 13px;
    }
    .contact-us-section {
        margin-bottom: 43%;
    }
    .contact-us-section > div:nth-child(1) form{
        width: 80vw;
        left: 10vw;
    }
    .contact-us-section > div:nth-child(2){
        height: 75vw;
    }
    /* .main-app-features .option-bar{
        flex-wrap: wrap;
        gap: 0.5rem;
        bottom: -1.9rem;
    }
    .main-app-features .option-bar .btn-div{
        padding: 0.1rem 0.5rem;
        font-weight: normal;
        margin-bottom: 0.3rem;
    }
    .main-app-features .option-bar .btn-div a{
        font-size: 0.1rem;
    }
    .main-app-features .option-bar .btn-div .pointer{
        width: 0.7rem;
        bottom: -6px;
        height: 0.7rem;
    } */
    .main-nev .bottom{
        gap: 0.3rem;
    }
    .advice-card-container {
        gap: 1rem;
        padding: 0 0.5rem;
    }
    .advice-card-container .advice-card{
        width: 15rem;
        height: 15rem;
    }
    .hero-section-one {
        padding: 0 3rem;
        display: flex;
        justify-content: center;
    }
    .hero-section-one div:nth-child(1), .hero-section-one div:nth-child(2){
        bottom: -20%;
        right: 2%;
        width: 90vw;
    }
    
    .client-section{
        display: flex;
        flex-wrap: wrap;
    }
    .client-section > div{
        width: 100%;
        padding: 1.5rem 4rem;
    }
    .nutri-source-section{
        padding: 2rem 1rem;
    }
    .nutri-source-section .container {
        width: 97%;
    }
}
@media screen and (max-width:800px){
    
    .contact-us-container {
        padding: 3rem 1rem;
    }
    .contact-us-container div{
        display: flex;
        align-items: center;
        flex-direction: column;
    }
    .contact-us-container div:nth-child(1){
        width: 100%;
        margin-bottom: 1.5rem;
    }
    .contact-us-container div:nth-child(2){
        width: 100%;
    }
    .main-fertilizer .sec-three > div:nth-child(2) > div:nth-child(1){
        width: 100%;
    }
    .main-fertilizer .sec-three > div:nth-child(2) > div:nth-child(2){
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0;
    }
    .main-fertilizer .sec-three > div:nth-child(2) > div:nth-child(2) img{
        width: 40%;
    }
    .goals-section{
        flex-wrap: wrap;
        justify-content: center;
    }
    .goals-section > div:nth-child(2){
        width: 80%;
        margin-top: 2rem;
    }
    .goals-section > div:nth-child(1){
        min-width: 100%;
        justify-content: center;
    }
    .goals-section > div:nth-child(1) img{
        width: 35%;
    }
}
@media screen and (max-width:750px){
    .main-fertilizer .sec-four > div:nth-child(2){
        /* background-color: red; */
        width: 100%;
    }
    .main-fertilizer .sec-four > div:nth-child(1){
        width: 100%;
        padding-right: 2%;
    }
    .main-fertilizer .sec-one > div {
        margin-bottom: 50vh;
    }
    .main-fertilizer .sec-one > div> div {
       left: 5vw;
    }
}
@media screen and (max-width:765px){
    .aboutUs-container .details{
        padding: 0 3rem;
    }
    .app-features-sec div h2{
        font-size: 25px;
        line-height: 30px;
        margin: 0;
        margin-top: 30px;
        padding: 0;
    }
   
    .santa-section button{
        font-size: 15px;
        padding: 6px 10px 6px 6px;
    }
    .santa-section button img {
        width: 35px !important;
        height: 35px;
    }
    .small{
        width: auto !important;
    }
    .contact-us-section > div:nth-child(1) form button{
        font-size: 15px !important;
    }
    .nutri-source-section .container div:nth-child(2) img{
        width: 50%;
    }
    .agri-advice-section button{
        font-size: 15px;
    }
    .features-section .features-container p {
        font-size: 15px;
    }
    .hero-section-one div h2{
        font-size: 25px;
        line-height: 30px;
    }
    .hero-section-one div button{
        font-size: 15px;
        padding: 5px 10px 5px 5px !important;
    }
    .hero-section-one div button img{
        width: 35px !important;
        height: 35px;
    }
    .contact-us-section {
        margin-bottom: 57%;
    }
    .contact-us-section > div:nth-child(1) form{
        width: 85vw;
        left: 6vw;
    }
    .contact-us-section > div:nth-child(2){
        height: 90vw;
    }
}
@media screen and (max-width:735px){
    
    .news-page .tooglebar{
        justify-content: center;
    }
    .main-footer {
        padding: 0.5rem;
    }
    .main-footer > div:nth-child(2){
        flex-wrap: wrap;
    }
    .main-footer > div:nth-child(2) > div:nth-child(3){
        margin-top: 3rem;
        width: 100%;
        display: flex;
        align-items: center;
    }
    .main-footer > div:nth-child(2) > div:nth-child(3) > div:nth-child(1){
        gap: 2.4rem;
        /* background-color: red; */
        width: 7rem
    }
    .aboutUs-container .santa-section img{
        width: 20vw;
    }
    .contact-no-section {
        background-color: #335E4D;
    }
    .contact-no-section div{
        height: fit-content;
    }
    .contact-no-section div:nth-child(1){
        width: 40%;
    }
    .contact-no-section div:nth-child(1) img{
        /* height: fit-content; */
        max-height: 80%;
    }
    .contact-no-section div:nth-child(2){
        width: 70%;
        padding: 0;
        height: fit-content;
        /* min-height: ; */
        padding-left: 2rem;
    }
    .contact-no-section div:nth-child(2) p{
        font-size: 12px;
        /* margin: 0; */
    }
    .our-advice-sec div:nth-child(2){
        padding: 0 2rem;
    }
}
@media screen and (max-width:699px){
    .hero-section-one {
        margin-bottom: 6rem;
    }
    .hero-section-one div:nth-child(1), .hero-section-one div:nth-child(2){
    bottom: -33%;
    width: 100%;
    right: 0;
    left: 0;
    height: 270px;
    }
}
@media screen and (max-width:675px){
    .proposition .sec-one > div:nth-child(1), .proposition .sec-three > div:nth-child(1){
        height: fit-content;
    }
   .hero-section-one div:nth-child(1) p{
        font-size: 1.5rem;
    }
    .main-hero{
        height: 30vw;
    }
    .main-hero h2{
        font-size: 25px;
        line-height: 30px;
        text-transform: uppercase;
    }
}
@media screen and (max-width:622px){
.contact-us-section {
    margin-bottom: 70%;
}
.contact-us-section > div:nth-child(1) form{
    width: 85vw;
    left: 5.5vw;
}
.contact-us-section > div:nth-child(2){
    height: 100vw;
}
}
@media screen and (max-width:600px){
    .updates-main{
        padding: 0 1rem;
    }
    .proposition .sec-one > div:nth-child(1) img{
        height: 10rem;
    }
    .proposition .sec-two > div:nth-child(2) img{
        height: 10rem;
    }
    .proposition .sec-three > div:nth-child(1) img{
        height: 10rem;
    }
     .goals-section > div:nth-child(2){
        width: 100%;
    }
    .float-section p{
        font-size: 15px !important;
        line-height: 26px !important;
    }
    .agri-advice-footer{
        margin-top: 1.4rem;
        justify-content: center;
    }
    .home-bg .container{
        width: 94vw;
        margin-top: 0rem;
    }
}
@media screen and (max-width:565px){
    .main-fertilizer .sec-one > div {
    margin-bottom: 60vh;
    }
    .investors-face-container{
        gap: 1.5rem;
        bottom: -2rem;
    }
    .investors-face-container > div {
        width: 10%;
    }
     /* .main-app-features .option-bar{
        flex-wrap: wrap;
        gap: 0.5rem;
        bottom: -4rem;
    } */
    .contact-us-section {
        margin-bottom: 80%;
    }
   
    .contact-us-section > div:nth-child(1) form{
        width: 90vw;
        left: 3vw;
    }
    .contact-us-section > div:nth-child(2){
        height: 110vw;
    }
    .hero-section-two{
        margin-top: 2rem;
    }
}
@media screen and (max-width:530px){
    .hero-section-one{
        margin-bottom: 5rem;
    }
    /* .float-section{
        position: absolute;
        top: 65%;
    } */
    .advice-card-container {
        gap: 2rem;
        padding: 0rem;
    }
    .advice-card-container .advice-card{
        width: 11rem;
    }
    .our-advice-sec div:nth-child(1){
        min-width: 0rem;
    }
}
@media screen and (max-width:510px){
    .contact-us-section {
        margin-bottom: 100%;
    }
    .contact-us-section > div:nth-child(1) form{
        width: 92vw;
        left: 2.3vw;
    }
    .contact-us-section > div:nth-child(2){
        height: 130vw;
    }
}
@media screen and (max-width:500px){
    .aboutUs-container .details{
        padding: 0 1rem;
    }
    .main-nev .top div{
        gap: 0.5rem;
    } 
    .aboutUs-container .santa-section img{
        width: 30vw;
    }
}
@media screen and (max-width:485px){
     .hero-section-one {
        margin-bottom: 37%;
    }
    .hero-section-one div:nth-child(2){
        bottom: -45%;
        width: 100%;
        right: 0;
        left: 0;
        height: 270px;
    } 
     .main-fertilizer .sec-one > div {
        margin-bottom: 70vh;
    }
    .news-page .container{
        padding: 1.5rem 0;
    }
    .LanguageModule .goog-te-gadget .goog-te-combo {
        margin: 10px 16px;
    }
}
@media screen and (max-width:435px){
    /* .main-footer > div:nth-child(2) > div:nth-child(2){
        width: 8rem;
    } */
    .contact-us-section {
        margin-bottom: 125%;
    }
    .contact-us-section > div:nth-child(2){
        height: 150vw;
    }
    .contact-us-section > div:nth-child(1) form{
        left: 1.9vw;
    }
}
@media screen and (max-width:420px){
    .main-fertilizer .sec-one > div {
        margin-bottom: 70vh;
    }
    .contact-us-section > div:nth-child(1) form{
        left: 4.2vw;
    }
}
@media screen and (max-width:415px){
    .main-fertilizer .sec-one > div {
        margin-bottom: 80vh;
    }
}
@media screen and (max-width:404px){
   
    .investors-face-container{
        gap: 1.5rem;
        bottom: -1.6rem;
    }
    .main-hero-sub{
        height: 35vw;
    }
}
@media screen and (max-width:396px){
    .main-fertilizer .sec-one > div {
        margin-bottom: 90vh;
    }
    .contact-us-section {
        margin-bottom: 135%;
    }
    .contact-us-section > div:nth-child(1) form{
        width: 92vw;
        left: 4vw;
    }
    .contact-us-section > div:nth-child(2){
        height: 160vw;
    }
     .hero-section-one {
        margin-bottom: 66%;
    }
    .hero-section-one div:nth-child(2){
        bottom: -57%;
        width: 100%;
        right: 0;
        left: 0;
        height: 270px;
    }
}