/* GLOBAL ATTRIBUTES STYLING */
button{
    padding: 0.7rem 1.5rem;
    border-radius: 43px;
    border: none;
    font-weight: 600;
    cursor: pointer;
    display: flex;
    align-items: center;
    user-select: none;
}
button img{
    width: 25px !important;
    margin-right: 0.7rem !important;
}
button:hover{
    box-shadow: 0px 1px 10px 2px rgb(156, 156, 156);
    transition: 0.4s;
}
p, span{
    margin: 0;
    font-family: 'Poppins', sans-serif;
    line-height: 33px;
    font-size: 18px;
    line-height: 26px;
}
img{
    width: 100%;
}
a{
    text-decoration: none;
    cursor: pointer;
    font-weight: 500;
    display: flex;
    align-items: center;
}
#icon{
    cursor: pointer;
    /* border-radius: 50%; */
}
#icon:hover{
    transition: 0.4s;
    box-sizing: 50%;
}
h3, h1, h2,h4{
    font-family: 'Josefin Sans', sans-serif;
}
.LanguageModule {
    margin-top: 15px;
}
.LanguageModule span {
    display: none;
}
 .LanguageModule select {
    background: #28802f;
    border-radius: 3px;
    color: #fff;
    cursor: pointer;
    font-weight: 500;
    height: 27px;
    outline: none;
}
.LanguageModule .goog-te-gadget{
    color: #eaad00;
}
/* MAIN NEVBAR */
.main-nev-section{
    position: sticky;
    z-index: 5;
    display: block;
}
.container_news{
    padding: 1rem;
    box-sizing: border-box;
    display: flex;
    flex-wrap: wrap;
    gap: 0.6rem;
    justify-content: center;
    align-items: center;
}
.container_news .video_card{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: fit-content;
    max-width: 90%;
}
.main-nev{
    display: flex;
    position: relative;
    flex-direction: column;
    align-items: center;
    font-family: 'Inter', sans-serif;
}
.main-nev .top{
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    background-color: var(--color-primary-dark);
    color: white;
    padding-bottom: 1rem;
    padding: 0rem 1.6rem;
    box-sizing: border-box;
    
}
.main-nev .top div{
    display: flex;
    gap: 1rem;
    cursor: pointer;
}
.main-nev .top div:nth-child(2) img{
    width: 3rem;
}
.main-nev .top div:nth-child(2) {
    padding-bottom: 0.2rem;
}
.main-nev .top div:nth-child(2) p{
    padding-top: 0.26rem;
}
.main-nev .top div:nth-child(1){
   /* background-color: red; */
   width: 10rem;
}
.main-nev .top div:nth-child(1) img{
    width: 100%;
}
.main-nev .top span{
    font-family: 'Pattaya', sans-serif;
    font-size: 25px;
}
.main-nev .top p{
    font-weight: 600;
    font-size: 1rem;
    cursor: pointer;
}
.main-nev .bottom{
    display: flex;
    padding: 0.3rem 0;
    align-items: center;
    box-shadow: 0px 6px 15px 1px rgb(65, 65, 65);
    background-color: var(--color-orange-dark);
    color: white;
    height: fit-content;
    font-weight: 600;
    position: absolute;
    border-radius: 30px;
    bottom: -45%;
    justify-content: center;
    z-index: 5;
    width: 80%;
    position:absolute;
}
.main-nev .bottom .active-head{
    background-color: var(--color-primary-dark);
    box-shadow: 0px 0px 6px 1px rgba(78, 154, 30, 0.6);
    padding: 0.3rem 1rem;
}
.main-nev .bottom  .cnrtls > div{
    border-radius: 30px;
    padding: 0.3rem 1.2rem;
}
.main-nev .bottom  .cnrtls > div:hover{
    background-color: var(--color-primary-dark);
    box-shadow: 0px 0px 5px 1px #03612d;
    transition: 0.6s;
}
.main-nev .bottom  .cnrtls {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding:0.2rem 3rem;
    width: 100%;
}
.main-nev .bottom div a{
    font-size: 15px;
    color: white;
    font-weight: 600;
    cursor: pointer;
}
#bar-icons{
    cursor: pointer;
}
.main-nev .bottom .bar{
    display: none;
}
/* MAIN FOOTER */

.main-footer{
    background-image: url('../public/assets/images/footerBg.png');
    color: white;
    display: flex;
    flex-direction: column;
    padding:3rem 2rem;
    box-sizing: border-box;
}
.main-footer > div:nth-child(1){
    background-color: #d9ecd9d8;
    backdrop-filter: blur(2px);
    padding: 0rem 2rem;
    box-sizing: border-box;
    border-radius: 20px;
    display: flex;
    margin-bottom: 1rem;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    /* border-bottom: 1px solid #6b6c6b; */
}
.main-footer > div:nth-child(1) div:nth-child(1) {
    display: flex;
    align-items: center;
    cursor: pointer;
    justify-content: space-between;
    gap: 1rem;
    /* background-color: red; */
    width: 10rem;
    height: 4rem;
}

.main-footer > div:nth-child(1) div:nth-child(2) {
    /* background-color: orange; */
    height: 2rem;
}
.main-footer > div:nth-child(1) div img{
    width: 100%;
    cursor: pointer;
    height: 100%;
}
.main-footer > div:nth-child(1) div a{
    font-family: 'Pattaya';
    font-size: 1.3rem;
    color: white;
}
.main-footer > div:nth-child(1) div a p{
    margin-left: 0.6rem;
}
.main-footer > div:nth-child(2){
    display: flex;
    background-color: #d9ecd9d8;
    backdrop-filter: blur(2px);
    padding: 1rem 2rem;
    box-sizing: border-box;
    border-radius: 20px;
    justify-content: space-between;
    /* align-items: center; */
    letter-spacing: 1px;
}
.main-footer > div h2{
    text-align: left;
    color: var(--color-primary-dark);
}

.main-footer > div p{
    text-align: left;
    font-weight: 500;
    line-height: 43px;
    color:var(--color-orange-dark);
}
.main-footer > div p a {
    font-weight: 500;
    line-height: 43px;
    color:var(--color-orange-dark) !important;
    display: inline-block;
}
.main-footer > div:nth-child(2) > div:nth-child(1) > div p:hover{
    line-height: 43px;
    cursor: pointer;
}

.main-footer > div:nth-child(2) > div:nth-child(2){
    /* width: 9rem; */
    /* background-color: red; */
}
.main-footer > div:nth-child(2) > div:nth-child(2) > div {
    display: flex;
    flex-direction: column;
}
.main-footer > div:nth-child(2) > div:nth-child(2) > div > div {
    display: flex;
    /* background-color: red; */
    gap: 2rem;
}
.main-footer > div:nth-child(2) > div:nth-child(3){
    display: flex;
    align-items: center;
    flex-direction: column;
}
.main-footer > div:nth-child(2) > div:nth-child(3) > div:nth-child(1){
    display: flex;
    /* background-color: gray; */
    justify-content: space-between;
    align-items: center;
}
.main-footer > div:nth-child(2) > div a{
    color: white;
}
.main-footer > div:nth-child(2) div button a{
    color: white;
    font-size: 20px;
    font-weight: 600;
}
.main-footer > div:nth-child(2)  > div:nth-child(3) > div:nth-child(2) button{
    background-color: var(--color-orange-light);
    margin-top: 0.4rem;
    padding:5px 7px;
    margin-top: 0.8rem;
}
.main-footer > div:nth-child(2)  > div:nth-child(3) > div:nth-child(2) button a{
    font-size: 1.2rem;
}
.main-footer > div:nth-child(2) div button img{
    height: 30px;
    width: 30px !important;
}
.main-footer > div:nth-child(3){
    padding-top: 0.9rem;
    box-sizing: border-box;
    display: flex;
    justify-content: right;
}
.main-footer > div:nth-child(3) a{
    color: rgb(180, 180, 180);
    font-size: 1rem;
}


/* HOME PAGE HERO SECTION */

.hero-section-one{
    display: flex;
    align-items: center;
    height: 470px;
    padding: 0 7rem;
    box-sizing: border-box;
    position: relative;
}
.hero-section-one div{
    text-align: left;
    font-size: 20px;
    width: 40%;
}
.hero-section-one div:nth-child(1){
    width: 60% ;
}
.hero-section-one div button a{
    text-decoration: none;
    font-size: 20px;
    font-weight: 600;
    color: white;
}
.hero-section-one div button{
    background-color: var(--color-primary-light);
    margin-top: 0.4rem;
    padding:5px 10px;
}
.hero-section-one div button img{
    height: 35px;
    width: 35px !important;
}
.hero-section-one div h2{
    font-size: 30px;
    line-height: 35px;
    font-weight: 500;
    font-family: 'Josefin Sans', sans-serif;
    line-height: 40px ;
    margin-bottom: 0.9rem;
    color: white;
}
.float-section{
    position: absolute;
    bottom: -4rem;
    right: 5rem;
    width: 40%;
    height: fit-content;
    background-color: var(--color-orange-dark);
    padding: 1rem;
    box-sizing: border-box;
    box-shadow: 0px 4px 8px rgba(174, 174, 174, 0.25);
}
.float-section p:nth-child(1){
    margin-bottom: 0.6rem;
}
.float-section p{
    font-size:18px !important;
    line-height: 24px;
    color: white;
    line-height: 33px !important;
}
.underline-head{
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
    justify-content: center;
    width: 100%;
    padding: 0 !important;
    margin-top: 5.5rem;
}
.underline-head div{
    width: 5rem ;
    height: 5px !important;
    border-radius: 6px !important;
}
.underline-head h3{
    font-size: 30px;
    text-transform: uppercase;
    line-height: 35px;
    font-family: 'Josefin Sans', sans-serif;
    margin: 0 !important;
    padding: 0 !important;
}
.light{
    color: white;
}
.light div{
    background-color: white;
    margin-top: 0.3rem;
}
.dark{
    color: var(--color-primary-dark);
}
.dark div{
    background-color: var(--color-primary-dark);
    margin-top: 0.3rem;
}
.lightG{
    color: var(--color-primary-light);
}
.lightG div{
    background-color: var(--color-primary-light);
    margin-top: 0.3rem;
}
.orange{
    color: var(--color-orange-dark);
}
.orange p{
    color: #505050;
    font-weight: 500;
    margin-top: 1rem;
}
.orange div{
    background-color: var(--color-orange-dark);
    margin-top: 0.3rem;
}
.small{
    width: 45% !important;
}
.hero-section-two{
    display: flex;
    background-color: orange;
    flex-direction: column;
    
    align-items: center;
    justify-content: center;
    padding: 3rem 0;
    box-sizing: border-box;
}
.hero-section-two > div:nth-child(2){
    width: 95%;
}
.hero-section-two p{
    /* text-align: left; */
    font-size: 19px;
    line-height: 32px;
    word-spacing: 3px;
    margin-bottom: 1rem;
    font-weight: 400;
    color: white;
}
.hero-section-two button{
    color: var(--color-primary-dark);
    font-size: 20px;
}


/* CLIENT SECTION */

.client-section{
    display: flex;
}
.client-section > div:nth-child(1){
    background-color: #335E4D;
}
.client-section > div:nth-child(2){
    background-color: #9BB701;
}
.client-section > div:nth-child(3){
    background-color: var(--color-orange-dark);
}
.client-section > div{
    width: 33.3%;
    padding:2rem;
    box-sizing: border-box;
}
.light-org{
    background-color: var(--color-orange-light);
}
.dark-org{
    background-color: var(--color-orange-dark);
}
.teamSlider{
    display: flex;
    margin: 2rem 0;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}
.teamSlider > div{
    width: 100%;
    padding: 0 2rem;
    display: flex;
    align-items: center;
    box-sizing: border-box;
}
.teamSlider > div > div{
    text-align: left;
    color: white;
}
.teamSlider > div > div p{
    line-height: 33px;
}
.teamSlider .img-div2 img{
    width: 80px;
    height: 80px;
    margin-right: 1rem;
    object-fit: cover;
}
.teamSlider .img-cont img{
    border-radius: 50%;
    width: 85px;
    height: 85px;
    object-fit: cover;
}
.teamSlider .img-cont{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.teamSlider .img-div img{
    width: 80px;
    height: 80px;
    margin-right: 1rem;
    object-fit: cover;
    border-radius: 50%;
}


/* FEATURES SECTION */

.features-section{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 2.5rem 0rem;
}
.features-section .features-container p {
    font-size: 20px;
    font-weight: 500;
    color: #363636bd;
    text-transform: uppercase;
}
.features-section .features-container{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 85%;
    height: fit-content;
    margin: 2rem;
    margin-top: 5rem;
    gap: 3rem;
}
.features-section .features-container .card{
    display: flex;
    width: 20rem;
    padding: 1rem;
    box-sizing: border-box;
    position: relative;
    flex-direction: column;
    border-radius: 10px;
}
.features-section .features-container .card:nth-child(1){
    background-color: #335E4D;
}
.features-section .features-container .card:nth-child(2){
    background-color: #FB731B;
}
.features-section .features-container .card:nth-child(3){
    background-color: #9BB701;
}
.features-section .features-container .card .icon{
    position: absolute;
    top: -4rem;
}
.features-section .features-container .card .icon img{
    width: 7rem;
}
.features-section .features-container .card .container{
    color: white;
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: 1.5rem;
}
.features-section .features-container .card .container h2{
    text-decoration: underline;
    text-underline-offset: 12px;
    line-height: 35px;
    text-decoration-thickness: 3px;
    text-decoration-color: white;
}
.features-section .features-container .card {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    cursor: pointer;
}
.features-section .features-container .card:hover {
    box-shadow: 0px 0px 10px 3px gray;
    transition: 0.5s;
}
.features-section .features-container .card .container > div {
    margin-bottom: 1rem;
}
.features-section .features-container .card .container > div > div {
    display: flex;
    padding-left: 1rem;
    margin: 1.4rem 0;
    align-items: center;
    gap: 1.4rem;
}
.features-section .features-container .card .container > div > div span:hover{
    text-decoration: underline;
    text-underline-offset: 7px;
    transition: 0.6s;
    text-decoration-color: rgba(255, 255, 255, 0.478);
}
.features-section .features-container .card .container > div .arrow{
    width: 100%;
    display: flex;
    padding: 0 !important;
    justify-content: center;
    position: absolute;
    bottom: -3rem;
}
.features-section .features-container .card .container > div .arrow img{
    width: 50px;
}
.features-section .features-container .card .container > div > div img{
    width: 20px;
}
.features-section button{
    background-color: var(--color-primary-dark);
    color: white;
}


/* AGRICULTURE ADVICE */

.agri-advice-section{
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    background-color: rgb(247, 231, 201);
    padding: 2.5rem;
    box-sizing: border-box;
}
.agri-advice-section > div:nth-child(2) {
    width: 95%;
}
.agri-advice-section div:nth-child(2) p{
    font-size: 19px;
    line-height: 32px;
    word-spacing: 3px;
    margin: 1rem;
    font-weight: 500;
    color: rgb(53, 53, 53);
}
.agri-advice-section .slider{
    width: 100%;
}
.agri-advice-section .slider button{
    display: none !important;
}
.agri-advice-section .slider .image-card img{
    object-fit: cover;
    width: 100%;
    resize: none;
    height: 12rem;
}
.agri-advice-section .slider .image-card div{
    background-color: var(--color-orange-dark);
    display: flex;
    width: 100%;
    justify-content: center;
}

.agri-advice-section .slider .image-card div p{
    color: white;
    text-align: center;
    margin: 0;
    line-height: 30px;
}
.agri-advice-section button{
    background-color: var(--color-orange-dark);
    color: white;
    margin-top: 2rem;
    font-size: 20px;
}
.agri-advice-section button {
    content: '';
}
.agri-advice-footer{
    display: flex;
    align-items: center;
    justify-content: right;
    width: 100%;
    gap: 1rem;
}


/* NUTRI SOURCE INFORMATION SECTION */

.nutri-source-section{
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 4rem;
    box-sizing: border-boxslider;
    /* margin: 4rem 0; */
}
.nutri-source-section .container {
    width: 90%;
    border-radius: 58px;
    padding: 2rem 0;
    display: flex;
    align-items: center;
}
.nutri-source-section .container div:nth-child(1){
    width: 35%;
    display: flex;
    align-items: center;
}
.nutri-source-section .container div:nth-child(1) img{
    width: 70%;
}
.nutri-source-section .container div:nth-child(2){
    width: 75%;
    display: flex;
    flex-direction: column;
    text-align: left;
    
}
.nutri-source-section .container div:nth-child(2) h2{
    color: var(--color-orange-dark);
    font-size: 2rem;
}

.nutri-source-section .container div:nth-child(2) img{
    width: 40%;
    cursor: pointer;
    margin-bottom: 1rem;
}
.nutri-source-section .container div:nth-child(2) div{
    display: flex;
    align-items: center;
}
.nutri-source-section .container div:nth-child(2) div input[type="text"]{
    width: 60%;
    background-color: #E7E7E7;
    border-radius: 53px;
    border: none;
    padding: 0.4rem;
    padding-left: 1.2rem;
    color: #06A5FF;
    font-size: 1.3rem;
}
.nutri-source-section .container div:nth-child(2) div input[type="text"]:focus{
    outline: 2px solid var(--color-primary-dark);
}
.nutri-source-section .container div:nth-child(2) div #send-icon{
    position: absolute;
    padding: 10px;
    margin-left: 33.4%;
    cursor: pointer;
    background-color: var(--color-primary-dark);
    color: white;
    border-radius: 50%;
}


/* CONTACT US SECTION */

.contact-us-section{
    display: flex;
    width: 100%;
    margin-bottom: 3rem;
    height: 38vw;
}
.contact-us-section > div:nth-child(1){
    width: 50%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 2rem 0;
    box-sizing: border-box;
    position: relative;
    height: fit-content;
}
.contact-us-section > div:nth-child(1) form{
    border: 1px solid #C2C2C2;
    box-shadow: 0px 4px 4px rgba(139, 139, 139, 0.25);
    padding: 2rem ; 
    width: 95%;
    box-sizing: border-box;
    position: absolute;
    right: -3.5rem;
    top: 6rem;
    z-index: 10;
    background-color: white;
    display: flex;
    flex-direction: column;
    text-align: left;
}
.contact-us-section > div:nth-child(1) form p{
    color: #626262;
}
.contact-us-section > div:nth-child(1) form button{
    color: white;
    background-color: var(--color-orange-dark);
    width: fit-content;
    margin-top: 1rem;
    font-size: 18px;
}
.contact-us-section > div:nth-child(1) form label{
    margin-bottom: 0.7rem;
}
.contact-us-section > div:nth-child(1) form input[type="text"]{
    background-color: #EFEFEF;
    border: none;
    padding: 0.55rem;
    padding-left: 0.9rem;
    border-radius: 100px;
    font-size: 1rem;
}
.contact-us-section > div:nth-child(1) form input[type="text"]:focus{
    outline: 2px solid var(--color-primary-dark);
}
.contact-us-section > div:nth-child(1) form .flex{
    display: flex;
    gap: 2rem;
    margin: 1rem 0;
    justify-content: space-between;
}
.contact-us-section > div:nth-child(1) form .flex div{
    text-align: left;
    width: 45%;
    display: flex;
    flex-direction: column;
}
.contact-us-section > div:nth-child(1) form .full-width{
    text-align: left;
    width: 100%;
    display: flex;
    flex-direction: column;
}
.contact-us-section > div:nth-child(1) form .full-width textarea{
    background-color: #EFEFEF;
    border: none;
    padding: 0.9rem;
    font-family: 'Inter', sans-serif;
    padding-left: 0.6rem;
    font-size: 1rem;
    height: 7rem;
    resize: none;
}
.contact-us-section > div:nth-child(2){
    width: 50%;
}


/* CONTACT NUMBER SECTION */

.contact-no-section{
    display: flex;
    align-items: center;
    margin: 1.5rem 0;
}
.contact-no-section div:nth-child(1){
    width: 45%;
}
.contact-no-section div:nth-child(1) img{
    height: 11rem;
    width: 100%;
    object-fit: cover;
}
.contact-no-section div:nth-child(2){
    width: 55%;
    height: 10rem;
    display: flex;
    align-items: center;
    text-align: left;
    padding: 1rem 0 0 4rem;
    background-image: linear-gradient(to right, #335E4D, #335E4D);
}
.contact-no-section div:nth-child(2) p{
    color: white;
    font-size: 1.3rem;
}


/* MAIN HERO SECTION */

.main-hero{
    height: 17rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.main-hero h2{
    color: white;
    font-size: 30px;
    cursor: pointer;
    margin: 0;
    border-radius: 50px;
}
.main-hero div{
    background-color: white;
    height: 5px;
    margin-top: 0.4rem;
    border-radius: 25px;
    cursor: pointer;
}
.main-hero p{
    color: white;
    font-size: 20px;
    cursor: pointer;
}
.main-hero-sub{
    height: 15rem;
    display: flex;
    align-items: center;
    flex-direction: column;
    padding-top: 1.5rem;
    box-sizing: border-box;
}
.main-hero-sub h2{
    color: white;
    font-size: 30px;
    line-height: 35px;
    cursor: pointer;
}
.main-hero-sub p{
    color: white;
    font-size: 21px;
    width: 60%;
    cursor: pointer;
}
#largeHero{
    height: 25vw;
    margin-bottom: 3rem;
    
}


/* MAIN APP FEATURES SECTION */

.main-app-features .features-parent-div{
    position: relative;
    margin-bottom: 3rem;
}
.main-app-features .option-bar{
    height: fit-content;
    position: absolute;
    bottom: -1.5rem;
    width:100%;
    padding: 0.4rem;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1.5rem;
}

.main-app-features .option-bar .btn-div{
    padding: 0.5rem 1.2rem;
    border-radius: 43px;
    font-weight: 500;
    display: flex;
    align-items: center;
    position: relative;
    color: white;
}
.main-app-features .option-bar .btn-div a{
    color: white;
    font-size: 1rem;
}
.main-app-features .option-bar .btn-div .pointer{
    position: absolute;
    bottom: -7px;
    left: 24%;
    width: 1rem;
    height: 1rem;
    transform: rotate(45deg);
    background-color: white;
}
.main-app-features .option-bar .btn-div .op-1 ,.main-app-features .option-bar .btn-div .op-3,.main-app-features .option-bar .btn-div .op-5,.main-app-features .option-bar .btn-div .op-7{
    background-color: var(--color-orange-dark);
}
.main-app-features .option-bar .btn-div .op-2 ,.main-app-features .option-bar .btn-div .op-4,.main-app-features .option-bar .btn-div .op-6,.main-app-features .option-bar .btn-div .op-8{
    background-color:var(--color-primary-dark);
}
.main-app-features .option-bar .btn-div:hover{
    cursor: pointer;
    box-shadow: 0px 1px 10px 2px rgba(72, 72, 72,0.7);
    transition: 0.4s;
}
.main-app-features .option-bar .btn-div:nth-child(even){
    background-color: var(--color-primary-dark);
}
.main-app-features .option-bar .btn-div:nth-child(odd){
    background-color: var(--color-orange-dark);
}
.app-features-sec{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0.5rem 3rem;
    box-sizing: border-box;
    margin: 5rem 0;
}
.app-features-sec > div{
    display: flex;
    align-items: center;
    justify-content: center;
}
.app-features-sec div img{
    width: 65%;
}
.app-features-sec div h2{
    text-align: left;
    width: 100%;
    font-weight: 500;
    font-size: 30px;
}
.app-features-sec div p{
    color: #505050;
    line-height: 32px;
}
.app-features-sec .orange-hd{
    color: var(--color-orange-dark);
}
.app-features-sec .lOrange-hd{
    color: var(--color-orange-light);
}
.app-features-sec .green-hd{
    color: var(--color-primary-dark);
}
.app-features-sec .lGreen-hd{
    color: var(--color-primary-light);
}
.app-features-sec .llGreen-hd{
    color: #9BDE7A;
}
.app-features-sec .brown-hd{
    color: #8B5936;
}
.left .ind-img{
    width: 40%;
    justify-content: left;
}
.left .content-sec{
    text-align: left;
    width: 60%;
    padding-right: 7rem;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
}
.right .content-sec{
    width: 60%;
    padding-left: 7rem;
    box-sizing: border-box;
    display: flex;
    text-align: left;
    align-items: baseline;
    flex-direction: column;
}
.right .ind-img{
    width: 40%;
    box-sizing: border-box;
    justify-content: right;
}

.sub-heading-main{
    font-size: 30px;
}


/* ABOUT US SECTION  */
.aboutUs-container .details{
    text-align: left;
    padding: 0 8rem;
    text-align: center;
    box-sizing: border-box;
}
.aboutUs-container .details p{
    line-height: 36px;
    color:var(--color-orange-dark);
    letter-spacing: 1px;
    margin-bottom: 1rem;
}
.aboutUs-container .details p span{
    color: var(--color-orange-light);
    font-weight: 500;
}
.aboutUs-container .details p b{
    text-decoration: underline;
    text-underline-offset: 6.5px;
    text-decoration-color: #158b4a;
}
.aboutUs-container .details h3{
    text-align: center;
    color: var(--color-orange-dark);
    margin-top: 2rem;
}
.aboutUs-container .aboutSec{
    padding: 2rem;
    box-sizing: border-box;
    display: flex;
}
.aboutUs-container .aboutSec > div:nth-child(1){
    width: 35vw;
    flex-wrap: wrap;
    align-items: left;
    display: flex;
}
.aboutUs-container .aboutSec > div:nth-child(1) img{
    width: 85%;
}
.aboutUs-container .aboutSec div:nth-child(2) p{
    line-height: 32px;
}
.aboutUs-container .aboutSec div:nth-child(2){
    display: flex;
    justify-content: center;
    box-sizing: border-box;
    flex-direction: column;
    text-align: left;
    width: 50%;
    color: var(--color-primary-dark);
}
.aboutUs-container .aboutSec div:nth-child(2) div{
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    width: 70%;
    margin-top: 0.5rem;
}
.aboutUs-container .aboutSec div:nth-child(2) > div > div{
    background-color: var(--color-primary-dark);
    color: white;
    border-radius: 5px;
    padding: 0.4rem 0.5rem;
    box-sizing: border-box;
    width: fit-content;
}
.how-we-help-sec{
    margin-top: 5rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.how-we-help-sec h3{
    color: var(--color-orange-dark);
}
.how-we-help-sec .container{
    margin: 7rem 0;
}
.aboutUs-container .santa-section{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.aboutUs-container .santa-section img{
    width: 17rem;
}
.santa-section button a{
    text-decoration: none;
    color: white;
    font-size: 20px;
    font-weight: 400;
}
.santa-section button{
    background-color: var(--color-orange-dark);
    display: flex;
    align-items: center;
    font-family: Pattaya;
    padding:3px 12px 7px 7px;
    margin: 1rem 0;
}
.santa-section button img {
    width: 44px !important;
    height: 44px;
}


/* FARM SANTA ADICE SECTION */

.our-advice-sec{
    display: flex;
    min-height: 45vw;
    height: fit-content;
    padding-top: 5rem;
    box-sizing: border-box;
}
.our-advice-sec div:nth-child(1){
    width: 40%;
    height: fit-content;
}
.our-advice-sec div:nth-child(1) img{
    width: 60%;
}
.our-advice-sec div:nth-child(2){
    width: 45%;
    height: 70%;
    padding-right: 4rem;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: left;
    color: var(--color-primary-dark);
}
.our-advice-sec div:nth-child(2) p{
    color: #505050;
}
.our-advice-2 {
    display: flex;
    flex-direction: column;
    align-items: center;
}
.under-sub-heading{
    margin: 1rem;
    width: 40%;
    margin-bottom: 5rem;
    font-weight: 500;
    font-size: 18px;
    color: #323232 !important;
}
.advice-pagination{
    width: 100%;
    padding-bottom: 3rem;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
}
.advice-card-container{
    width: 100%;
    padding: 0 2rem;
    gap: 2rem;
    margin-bottom: 2rem;
    box-sizing: border-box;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
}
.advice-card-container .advice-card{
    width: 20rem;
    cursor: pointer;
    padding-bottom: 1rem;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
}
.advice-card-container .advice-card:hover{
    box-shadow: 1px 1px 10px 2px rgba(142, 142, 142,0.6);
    transition: 0.5s;
}
.advice-card-container .advice-card .container{
    height: 20rem;
    display: flex;
    flex-direction: column;
    position: relative;
    margin-bottom: 1.8rem;
}
.advice-card-container .advice-card .container .top{
    display: flex;
    padding: 0.8rem 1.1rem;
    box-sizing: border-box;
    align-items: center;
    justify-content: space-between;
}
.advice-card-container .advice-card .container .top p{
    color: white !important;
    font-size: 400 !important;
    padding: 0 0.6rem;
    box-sizing: border-box;
    font-size: 0.9rem;
    margin: 0 !important;
    background-color: #58585882;
    backdrop-filter: blur(2px);
    border-radius: 21.5px;
    cursor: pointer;
}
.advice-card-container .advice-card .container .top p:nth-child(2){
    width: 2rem !important;
    height: 2rem !important;
    display: flex;
    align-items: center;
    justify-content: center;
}
.advice-card-container .advice-card .container .top p:nth-child(2) a{
    color: white;
}
.advice-card-container .advice-card .container .bottom{
    position: absolute;
    bottom: -2.4rem;
    width: 100%;
    text-align: left;
    padding: 0.4rem 0;
    box-sizing: border-box;
}
.pagination-st{
    display: flex;
    gap: 0.7rem;
    justify-content: center;
    align-items: center;
    list-style: none;
}
.page-num-next{
    color: var(--color-primary-dark);
}
.page-num-prev{
    color: #87868A;
    font-weight: 400;
}
.page-num{
    background-color: #A2CC46;
    width: 1rem;
    height: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    padding: 0.3rem;
    border-radius: 5px;
}
.active{
    background-color: var(--color-primary-dark);
    color: white;
}


/* OUR GOAL SECTION */

.goals-section{
    display: flex;
    align-items: center;
    padding: 0.7rem 2rem;
    box-sizing: border-box;
}
.goals-section div:nth-child(1){
    width: 30%;
    display: flex;
    align-items: left;
}
.goals-section div:nth-child(1) img{
    width: 85%;
}
.goals-section div:nth-child(2){
    width: 70%;
    display: flex;
    flex-direction: column;
    align-items: left;
    height: fit-content;
    text-align: left;
    background-color: var(--color-primary-dark);
    color: white;
    padding: 1rem 2rem 4rem 2rem;
    box-sizing: border-box;
}
.goals-chart{
    margin-top: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.goals-chart img{
    margin-bottom: 6rem;
}
.goals-chart p{
    font-size: 18px;
    line-height: 26px;
}

/* INVESTORS SECTION */

.inv-hero-sec{
    position: relative;
    margin-bottom: 10%;
}
.investors-face-container{
    display: flex;
    align-items: center;
    justify-content: space-around;
    gap: 2.5rem;
    bottom: -4.7rem;
    position: absolute;
}
.investors-face-container > div {
    width: 8%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.investors-face-container > div > img{
    width: 100%;
    cursor: pointer;
    border-radius: 50%;
}
.investors-face-container > div > img:hover{
    box-shadow: 0px 5px 10px 4px rgba(147, 147, 147,0.6);
    transition: 0.5s;
}


/* TEAM DETAIL HERO SECTION */

.team-detail-main{
    margin-bottom: 1.3rem;
}
.team-chart{
    margin-top: 5rem;
    padding: 1rem 10rem;
    box-sizing: border-box;
}
.chart-layout{
    position: relative;
}
#img1{
position: absolute;
top: 1.6%;
left: 30.04%;
width: 17.85%;
}
#img2{
position: absolute;
top: 21.6%;
left: 52.075%;
width: 17.85%;
}
#img3{
position: absolute;
top: 41.67%;
left: 29.87%;
width: 17.85%;
}
#img4{
position: absolute;
top: 61.64%;
left: 51.999%;
width: 18%;
}
#img5{
position: absolute;
top: 81.8%;
left: 30.13%;
width: 18%;
}
.top-agro{
 background-color: var(--color-primary-dark);
 display: flex;
 align-items: center;
 justify-content: center;
 flex-direction: column;
 padding: 1.5rem 0;
 box-sizing: border-box;
}
.top-agro > p{
    color: white !important;
    font-weight: 400 !important;
}
.top-agro .top-agro-container{
    margin-top: -3rem;
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    justify-content: space-evenly;
}
.top-agro .top-agro-container div{
    display: flex;
    width: 10% !important;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: white;
    font-weight: 300;
    cursor: pointer;
}
.top-agro .top-agro-container div img{
    width: 100%;
}


/* TERMA AND CONDITIONS */
.home-bg{
    background-color: rgba(234, 234, 234,0.6);
    padding: 2rem 0;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
}
.home-bg .container{
    margin-top: 3rem;
    background-color: white;
    width: 80%;
    border-radius: 10px;
    height: fit-content;
    padding:1rem 2rem;
    box-sizing: border-box;
    box-shadow: 1px 1px 10px 1px rgb(196, 196, 196);
    
}
.home-bg .container > div h2{
    user-select: none;
    cursor: pointer;
}
.home-bg .container > div{
    text-align: left;
    font-size: 1.3rem;
    color: var(--color-primary-dark);
}
.home-bg .container section {
    text-align: left;
    padding-bottom: 2rem;
}
.home-bg .container section ul {
    margin-bottom: 2rem;
}
.home-bg .container section ul li{
    margin-bottom: 0.7rem;
    font-size: 1rem;
    margin-left: 0.4rem;
    font-family:'Poppins', sans-serif;
}
.home-bg .container section h4{
    color: var(--color-primary-dark);
    font-size: 1.1rem;
}
.home-bg .container section h3{
    color: rgb(75, 75, 75);
}
.home-bg .container footer p{
    user-select: none;
    cursor: pointer;
}
.home-bg .container footer{
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
}
.home-bg .container a{
    color: var(--color-orange-dark);
    display: block;
}
.home-bg .container section span{
    font-size: 1rem;
    font-weight: 500;
}


/* CONTACT US */

.contact-us-main{
    margin: 4rem 0;
}
.contact-us-container{
    display: flex;
    flex-wrap: wrap;
    padding-top: 3rem;
    box-sizing: border-box;
}
.contact-us-container a {
    display: inline-block;
    color: #3a3a3a;

}
.contact-us-container div:nth-child(1){
    /* background-color: orange; */
    width: 50%;
    /* display: flex;
    align-items: center; */
    min-width: fit-content;
}
.contact-us-container div:nth-child(1) > div{
    /* background-color: red; */
    width: 100%;
    margin: 1rem 0;
    margin-bottom: 3rem;
}
.contact-us-container div:nth-child(1) h3{
    margin-bottom: 1rem;
}
.contact-us-container div:nth-child(1) p{
    margin-bottom: 0.4rem;
}
.contact-us-container div:nth-child(2){
    width: 50%;
    min-width: fit-content;
}


/* FERTILIZER */

.main-fertilizer .sec-one {
    padding: 3rem 0;
    box-sizing: border-box;
    display: flex;
}
.main-fertilizer .sec-one > div{
    position: relative;
    width: 30%;
    display: flex;
}
.main-fertilizer .sec-one > div > div{
    position: absolute;
    right: -220%;
    top: 5%;
    width: 70vw;
    height: fit-content;
    background-color: var(--color-primary-dark);
    color: white;
    text-align: left;
    padding: 1.1rem;
    box-sizing: border-box;
}
.main-fertilizer .sec-two{
    background-color: var(--color-orange-dark);
    color: white;
    text-align: center;
    padding: 2rem 0;
    box-sizing: border-box;
}
.fertiSlider .img-count{
    display: flex;
    margin: 1rem 0;
    justify-content: space-evenly;
}
.fertiSlider .img-count > div{
    display: flex;
    flex-direction: column;
    align-items: center;
}
.fertiSlider .img-count > div > div{
    width: 7rem;
    height: 7rem;
    background-color: white;
    border-radius: 50%;
}
.fertiSlider .img-count > div span{
    margin-top: 1rem;
}
.main-fertilizer .sec-three{
    margin: 3rem 0;
    color: #363636;
}
.main-fertilizer .sec-three > div:nth-child(2){
    margin: 2rem 0;
    display: flex;
    flex-wrap: wrap;
    text-align: center;
}
.main-fertilizer .sec-three > div:nth-child(2) > div:nth-child(1){
    text-align: left;
    padding: 1.5rem;
    box-sizing: border-box;
    width: 60%;
}
.main-fertilizer .sec-three > div:nth-child(2) > div:nth-child(2){
    width: 40%;
    display: flex;
    align-items: center;
    padding: 2rem;
    box-sizing: border-box;
}
.main-fertilizer p{
    line-height: 32px;
    margin-bottom: 1.2rem;
}
.main-fertilizer h4{
    font-size: 2rem;
    margin: 0;
    margin-bottom: 1.5rem;
}
.main-fertilizer h4{
    color: var(--color-primary-dark);
}
.main-fertilizer .sec-four{
    display: flex;
    flex-wrap: wrap;
    color: #363636;
}
.main-fertilizer .sec-four > div:nth-child(1){
    width: 35%;
}
.main-fertilizer .sec-four > div:nth-child(1) > div:nth-child(1) img{
    border-radius: 0 242px 242px 0;
}

.main-fertilizer .sec-four > div:nth-child(1) > div:nth-child(2){
    display: flex;
    margin: 0.8rem 0;
}
.main-fertilizer .sec-four > div:nth-child(1) > div:nth-child(2) > div:nth-child(1){
    padding-right: 0.3rem;
    box-sizing: border-box;
    width: 70%;
}
.main-fertilizer .sec-four > div:nth-child(1) > div:nth-child(2) > div:nth-child(2){
    display: flex;
    width: 30%;
    align-items: center;
    flex-direction: column;
}

.main-fertilizer .sec-four > div:nth-child(1) > div:nth-child(2) > div:nth-child(2) img:nth-child(1){
    border-radius: 0 0 121.5px 0;
}
.main-fertilizer .sec-four > div:nth-child(1) > div:nth-child(2) > div:nth-child(2) img:nth-child(2){
    border-radius: 0 100px 0 0;
    margin: 0.25rem 0;
}
.main-fertilizer .sec-four > div:nth-child(1) > div:nth-child(2) > div:nth-child(2) img:nth-child(3){
    border-radius: 50%;
}
.main-fertilizer .sec-four > div:nth-child(1) > div:nth-child(4){
    display: flex;
    margin: 0.8rem 0;
    align-items: center;
}
.main-fertilizer .sec-four > div:nth-child(1) > div:nth-child(4) > div:nth-child(1){
    width: 40%;
}

.main-fertilizer .sec-four > div:nth-child(1) > div:nth-child(4) > div:nth-child(1) img{
    border-radius: 50%;
}
.main-fertilizer .sec-four > div:nth-child(1) > div:nth-child(4) > div:nth-child(2){
    width: 60%;
}
.main-fertilizer .sec-four > div:nth-child(1) > div:nth-child(4) > div:nth-child(2) img{
    height: 15rem;
    padding-left: 0.5rem;
    box-sizing: border-box;
}
/* .main-fertilizer .sec-four > div:nth-child(2) p{
    color: ;
} */
.main-fertilizer .sec-four > div:nth-child(2) p span{
    font-weight: 600;
    margin-right: 0.4rem;
}
.main-fertilizer .sec-four > div:nth-child(2){
    width: 65%;
    text-align: left;
    padding:0 2rem;
    box-sizing: border-box;
}
.proposition .sec-one{
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin: 3rem 0;
    height: fit-content;
}
.proposition p{
    line-height: 32px;
}
.proposition h3{
    font-size: 1.5rem;
}
.proposition .sec-one > div:nth-child(1){
    width: 45%;
    height: 25rem;
}

.proposition .sec-one > div:nth-child(1) img{
    border-radius: 0 270.5px 270.5px 0;
    height: 100%;
}
.proposition .sec-one > div:nth-child(2){
    width: 55%;
    padding: 0 3rem;
    box-sizing: border-box;
    justify-content: center;
    display: flex;
    flex-direction: column;
    text-align: left;
}
.proposition .sec-two{
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    width: 100%;
}
.proposition .sec-two > div:nth-child(1){
    width: 55%;
    padding: 0 3rem;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    text-align: left;
}
.proposition .sec-two > div:nth-child(2){
    width: 45%;
    height: 25rem;
}
.proposition .sec-two > div:nth-child(2) img{
    border-radius: 270.5px 0 0 270.5px;
    height: 100%;
}
.proposition .sec-three {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin-bottom: 2rem;
}
.proposition .sec-three > div:nth-child(1){
    width: 45%;
    height: 25rem;
}

.proposition .sec-three > div:nth-child(1) img{
    border-radius: 0 270.5px 270.5px 0;
    height: 100%;
}
.proposition .sec-three > div:nth-child(2){
    width: 55%;
    padding: 0 3rem;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    text-align: left;
}
.proposition .sec-three > div:nth-child(2) > div{
    margin: 0.9rem 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.proposition .sec-three > div:nth-child(2) > div > div{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.proposition .sec-three > div:nth-child(2) > div > div > div{
    width: 5rem;
    height: 5rem;
    border-radius: 50%;
}
.proposition .sec-two ul li{
    color: rgb(80, 80, 80);
    font-size: 19px;
    line-height: 33px;
}

/* VALUE CHAIN MODAL PAGE */
.value-chain .container {
    padding: 3rem;
    box-sizing: border-box;
}
.value-chain .container > div:nth-child(1){
    display: flex;
    align-items: center;
    justify-content: center;
}
.value-chain .container > div:nth-child(1) img{
    width: 50%;
    border-radius: 5px;
}

.value-chain .container > div:nth-child(2){
    margin-top: 2rem;
    text-align: left;
}

.value-chain .container > div:nth-child(2) p{
    margin-bottom: 0.8rem;
    color: var(--color-primary-dark);
    line-height: 32px;
}
.value-chain .container > div:nth-child(2) h2{
    margin-top: 3rem;
    color: var(--color-orange-dark);
}


/* NEWS PAGE  */
.news-page{
    position: relative;
}
.news-page .tooglebar{
    display: flex;
    padding: 1rem 3rem;
    gap: 0.5rem;
    box-sizing: border-box;
}

.news-page .tooglebar div{
    padding: 0.3rem 1rem;
    user-select: none;
    font-weight: bold;
    color: rgb(122, 121, 121);
}
.news-page .tooglebar div:hover{
    cursor: pointer;
}
.news-page .tooglebar .active{
    background-color: var(--color-orange-dark);
    color: white;
    border-radius: 5px;
}
.news-page .container{
    display: flex;  
    flex-wrap: wrap;
    width: fit-content;
    justify-content: space-between;
    /* align-items: flex-start; */
    gap: 2rem;
    padding: 1rem 5rem;
    box-sizing: border-box;
}
.news-page .read-more{
    position: absolute;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background-image: linear-gradient(to top, white, rgba(255, 255, 255, 0.858), rgba(255, 255, 255, 0.304));
    width: 100%;
    padding: 0 !important;
    height: 10rem;
}

.news-page .read-more h2{
    color: var(--color-orange-dark);
    cursor: pointer;
    user-select: none;
}

.news-page .container .card{
    display: flex;
    width: 25rem;
    height: fit-content;
    border-radius: 7px;
    background-color: #e2e1e16a;
    flex-direction: column;
}
.news-page .container .card:hover{
    cursor: pointer;
    box-shadow: 0px 0px 10px 2px rgba(184, 184, 184, 0.648);
    transition: 0.5s;
}
.news-page .container .card > div:nth-child(1){
    display: flex;
    height: 12rem;
    border-radius: 7px 7px 0 0;
    align-items: center;
    justify-content: center;
    position: relative;
}
.news-page .container .card > div:nth-child(1) #next{
    position: absolute;
    color: rgba(255, 255, 255, 0.741);
    font-size: 2.5rem;
}
.news-page .container .card > div:nth-child(1) img{
    height: 100%;
    border-radius: 7px 7px 0 0;
}
.news-page .container .card > div:nth-child(2){
    display: flex;
    padding: 0.2rem 1.3rem 0.8rem 1rem;
    box-sizing: border-box;
    text-align: left;
    flex-direction: column;
}
.news-page .container .card > div:nth-child(2) h3{
    color: var(--color-orange-dark);
    padding: 0 !important;
}
.news-page .container .card > div:nth-child(2) p{
    font-size: 1rem;
    color: rgb(53, 53, 53);
    letter-spacing: 1px;
    line-height: 32px;
}
.news-page .container .card > div:nth-child(2) p span{
    color: var(--color-orange-dark);
    font-size: 0.8rem;
    font-weight: 500;
}


/* UPDATES MAIN */
.updates-main{
    padding: 1rem 3rem;
    box-sizing: border-box;
    display: flex;
}
.updates-main .cont{
    width: 65%;
    padding-right: 3rem;
    box-sizing: border-box;
}
.updates-main .cont .card{
    display: flex;
    background-color:  rgba(207, 207, 207, 0.259);
    border-radius: 10px;
    padding: 1rem;
    box-sizing: border-box;
    margin-bottom: 1rem;
    width: 100%;
    flex-direction: column;
}
.updates-main .cont .card:hover{
    cursor: pointer;
    box-shadow: 0px 0px 10px 2px rgba(184, 184, 184, 0.648);
    transition: 0.5s;
}
.updates-main .cont .card > div:nth-child(1){
    display: flex;
    padding-bottom: 0.4rem;
    box-sizing: border-box;
    align-items: center;
    justify-content: space-between;
}
.updates-main .cont .card > div:nth-child(1) > div:nth-child(1){
    display: flex;
    gap: 1rem;
    color: var(--color-orange-dark);
    align-items: center;
}
.updates-main .cont .card > div:nth-child(1) > div p{
    color: gray;
    font-size: 1rem;
}
.updates-main .cont .card > div:nth-child(1) img{
    width: 50px;
    height: 50px;
    padding: 0.1rem;
    border: 3px solid var(--color-orange-dark);
    border-radius: 50%;
}
.updates-main .cont .card > div:nth-child(2){
    height: 15rem;
    width: 100%;
}
.updates-main .cont .card > div:nth-child(2) img{
    width: 100%;
    border-radius: 5px;
    height: 100%;
}
.updates-main .cont .card > div:nth-child(3){
    display: flex;
    text-align: left;
    flex-direction: column;
}
.updates-main .cont .card > div:nth-child(3) h3{
    color: rgb(76, 76, 76);
}
.updates-main .cont .card > div:nth-child(3) p{
    color: rgb(109, 109, 109);
    letter-spacing: 1px;
    line-height: 32px;
}
.updates-main .cont .card > div:nth-child(3) p > span{
    color: var(--color-orange-dark);
    user-select: none;
    font-weight: 500;
    font-size: 0.9rem;
}
.updates-main .updates{
    width: 35%;
    display: flex;
    flex-direction: column;
    gap: 1rem;
}
.updates-main .updates .update{
    display: flex;
    background-color:  rgba(207, 207, 207, 0.259);
    border-radius: 10px;
    padding: 1rem;
    box-sizing: border-box;
    flex-direction: column;
}
.updates-main .updates .update:hover{
    cursor: pointer;
    box-shadow: 0px 0px 10px 2px rgba(184, 184, 184, 0.648);
    transition: 0.5s;
}
.updates-main .updates .update > div:nth-child(1) {
    display: flex;
    font-weight: 500;
    color: var(--color-orange-dark);
    gap: 1rem;
    align-items: center;
}
.updates-main .updates .update > div:nth-child(1) img{
    width: 50px;
    height: 50px;
    border-radius: 50%;
}
.updates-main .updates .update > div:nth-child(2) {
    text-align: left;
}
.updates-main .updates .update > div:nth-child(2) p{
    color: rgb(102, 101, 101);
    letter-spacing: 1px;
    line-height: 32px;
    font-size: 1rem;
    margin-top: 1rem;
}
.updates-main .updates .update > div:nth-child(3) {
    display: flex;
    color: var(--color-orange-dark);
    text-align: left;
    justify-content: left;
    flex-direction: column;
}
.updates-main .updates .update > div:nth-child(3) > div {
    display: flex;
    align-items: center;
    gap: 1rem;
}
.updates-main .updates .update > div:nth-child(4) > #link button {
    background-color: var(--color-orange-dark);
    margin-top: 1rem;
    color: white;
}
.updates-main .popular{
    display: flex;
    flex-direction: column;
    text-align: left;background-color:  rgba(207, 207, 207, 0.259);
    border-radius: 10px;
    padding: 1rem;
    box-sizing: border-box;
    flex-direction: column;
}
.updates-main .popular:hover{
    cursor: pointer;
    box-shadow: 0px 0px 10px 2px rgba(184, 184, 184, 0.648);
    transition: 0.5s;
}
.updates-main .popular h2{
    color: var(--color-orange-dark);
}
.updates-main .popular .card {
    margin-bottom: 2rem;
}
.updates-main .popular .card > div:nth-child(1){
    height: 10rem;
}
.updates-main .popular .card > div:nth-child(1) img{
    height: 100%;
    border-radius: 5px;
}
.updates-main .popular .card > div:nth-child(2) h3{
    color: var(--color-orange-dark);
}
.updates-main .popular .card > div:nth-child(2) p{
    color: rgb(102, 101, 101);
    letter-spacing: 1px;
    line-height: 32px;
    font-size: 1rem;
    margin-bottom: 1rem;
}
.updates-main .popular .card > div:nth-child(2) span{
    color: var(--color-orange-light);
    font-weight: 600;
    letter-spacing: 1px;
    font-size: 1rem;
}