@import url('https://fonts.googleapis.com/css2?family=Josefin+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,300&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700&display=swap');
.App {
  text-align: center;
}
:root{
  --color-primary-dark: #eaad00;
  --color-primary-light: #9BB701;
  --color-orange-light: #FF9A35;
  --color-orange-dark: #28802f;

}